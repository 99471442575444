
  <template>
  <b-container fluid class="account-headers accounts-tabs-height">
    <b-row>
      <b-col cols="1" class="text-center">
        <b-img src="/img/account-icons.svg"></b-img>
      </b-col>
      <b-col cols="11">
        <b-row>
          <b-col cols="12">
            <h2>{{$t("profile.infotexts.Plans&Billing-title")}}</h2>
            <p>{{$t("profile.infotexts.Plans&Billing")}}</p>
            <hr />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row
      v-if="!accountChange"
      class="plan-overview-tabs"
      id="plan-overview-tabs"
    >
      <b-col cols="11" offset-md="1">
        <div>
          <div class="account-type">
            <span> {{$t("profile.contents.plans&billing.plans.account-type")}}:</span>
            <b-form-group id="input-group-3" label="" label-for="input-3">
              <b-form-select
                v-model="selectedPlanType"
                :options="planTypes"
                size="sm"
                class=""
              ></b-form-select>
            </b-form-group>
          </div>

          <b-tabs content-class="mt-3" v-if="selectedPlanType == 'single'">
            <b-tab
              :title="$t('buttons.'+ type)"
              v-for="(plan, type) in PLAN_TYPES"
              :key="type"
              :active="myPlan.type === type"
            >
              <div class="row">
                <div
                  class="col-md-4"
                  v-for="(item, index) in allPlans[type]"
                  :key="index"
                >
                  <div class="plan-cards">
                    <h3>
                      {{ item.name }}
                      <img
                        v-if="myPlan.id == item.id"
                        src="/img/green-tick-plan.svg"
                        class="img-fluid ml-3"
                        alt=""
                      />
                    </h3>
                    <hr class="divide-plan-name" />
                    <h5 v-if="item.info.prices">
                      € {{ (item.info.prices["EUR"] / PLAN_TYPES[type].month).toFixed(2) }} <span>/{{$t("profile.contents.plans&billing.plans.month")}}</span>
                    </h5>
                    <h5 v-else>€ 0 <span>/{{$t("profile.contents.plans&billing.plans.month")}}</span></h5>
                    <h6 v-if="item.info.prices">
                      {{ $t('profile.contents.plans&billing.plans.billed' + $_.toLower(PLAN_TYPES[type].name)) }} € {{ item.info.prices["EUR"] }}
                    </h6>
                    <h6 v-else>€ 0</h6>
                    <a
                      href=""
                      v-if="myPlan.id !== item.id && item.default === true"
                      class="btn btn-block downgrade-buttons disabled"
                    >
                        {{ item.name }}
                    </a>
                    <a
                      href=""
                      v-else-if="myPlan.id !== item.id && item.level < myPlan.level"
                      class="btn btn-primary btn-block upgrade-buttons disabled"
                    >
                        {{ PLAN_TYPES[type].name }}  {{ item.name }}
                    </a>
                    <a
                      href=""
                      v-else-if="myPlan.id !== item.id && item.level > myPlan.level"
                      class="btn btn-primary btn-block upgrade-buttons"
                      @click.prevent="openUpgradeModal(item)"
                    >
                        {{ PLAN_TYPES[type].name }} {{ item.name }}
                    </a>
                    <a
                      v-else-if="myPlan.id === item.id && item.default === true"
                      class="btn btn-block btn-success current-buttons disabled"
                    >
                        {{$t("profile.contents.plans&billing.plans.current")}}
                    </a>
                    <a
                      v-else-if="myPlan.id === item.id && item.default === false && myPlan.cancelled === true"
                      class="btn btn-block btn-success current-buttons"
                    >
                          {{$t("profile.contents.plans&billing.plans.current")}}
                    </a>
                    <a
                      v-else-if="myPlan.id === item.id && item.default === false && myPlan.cancelled === false && hover === false"
                      class="btn btn-block btn-success current-buttons"
                      @mouseover="item.default === false ? (hover = true) : null"
                      @mouseleave="item.default === false ? (hover = false) : null"
                    >
                          {{$t("profile.contents.plans&billing.plans.current")}}
                    </a>
                    <a
                      v-else
                      href=""
                      @mouseover="hover = true"
                      @mouseleave="hover = false"
                      @click.prevent="cancelPlanModal"
                      class="btn btn-block cancelling-buttons"
                    >
                        {{$t("buttons.cancel")}}
                    </a>
                    <ul>
                      <li v-for="(spec, index) in $_.get(item, 'info.specs', [])" :key="index">
                        <img
                          src="/img/plan-list-item.svg"
                          class="img-fluid"
                        />
                        {{ $t(`plan-features.${spec}`) }}
                      </li>
                    </ul>

                    <p v-if="calcSavePaid(item, type, index) !== false">
                      {{$t("profile.contents.plans&billing.plans.switch")}}  {{ calcSavePaid(item, type, index).toFixed(2) }}€
                    </p>
                    <p v-else></p>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>

          <!-- Bu kısım enterprise planlar -->
          <b-tabs v-else content-class="mt-3">
            <b-tab title="Enterprise" active>
              <div class="row">
                <div
                  class="col-md-4"
                  v-for="(item, value) in enterprisePlans"
                  :key="value"
                >
                  <div class="plan-cards">
                    <h3>{{ item.name }}</h3>
                    <hr class="divide-plan-name" />

                    <h5>€ {{ item.price }} <span> /{{$t("buttons.yearly")}}</span></h5>

                    <h6>{{$t("profile.contents.plans&billing.plans.billedyearly")}} € {{ item.billed }}</h6>
                    <div class="enterprise-plan-buttons-wrappper">
                    <a
                      href="mailto:support@connectter.com"
                      class="plan-upgrade-btn"
                      >{{$t("buttons.contact-us")}}</a
                    >
                    </div>
                    <ul>
                      <li v-for="(spec, index) in item.specs" :key="index">
                        <img
                          src="/img/plan-list-item.svg"
                          class="img-fluid"
                        />
                        {{ $t(`plan-features.${spec}`) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
    </b-row>

    <!-- bu kısım upgrade yapılırsa -->
    <b-row v-if="accountChange">
      <b-col cols="11" offset-md="1">
        <div class="row">
          <div class="col-md-4">
            <div class="plan-cards">
              <h3>{{ selectPlan.name }}</h3>
              <hr class="divide-plan-name" />
              <h5>€ {{ (selectPlan.info.prices["EUR"] / PLAN_TYPES[selectPlan.type].month).toFixed(2) }} <span>/{{$t("profile.contents.plans&billing.plans.month")}}</span></h5>
              <h6>{{$t("profile.contents.plans&billing.plans.billed")}} {{ PLAN_TYPES[selectPlan.type].name }} at € {{ selectPlan.info.prices["EUR"] }}</h6>
              <ul>
                <li v-for="(spec, index) in $_.get(selectPlan, 'info.specs', [])" :key="index">
                    <img
                        src="/img/plan-list-item.svg"
                        class="img-fluid"
                    />
                    {{ $t(`plan-features.${spec}`) }}
                </li>
              </ul>
              <b-button variant="outline-danger" @click="() => (accountChange = false)">{{$t("buttons.cancel")}}</b-button>
            </div>
          </div>
          <div class="col-md-8">
            <iframe
              v-if="isNew"
              @load="onload3d"
              class="w-100 h-100"
              :src="paddle3dUrl"
              frameborder="0"
            />
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="11" offset-md="1" class="account-sub-header pt-3 pb-5 mb-3">
        <h3 class="mb-5 mt-5">{{$t("profile.contents.plans&billing.plans.title")}}</h3>
        <p>
          {{$t("profile.contents.plans&billing.plans.p1")}}
        </p>
        <p>
          {{$t("profile.contents.plans&billing.plans.p2")}}
        </p>
        <p class="mb-5">{{$t("profile.contents.plans&billing.plans.p3")}}</p>

        <a href="mailto:support@connectter.com"
         class="plan-contact-btn">{{$t("buttons.contact")}}</a>
      </b-col>
    </b-row>

    <sweet-modal class="modal-x" id="changePlansUpgradeModal" ref="upgradeModal">
      <div class="row">
        <div class="col-md-12 text-left">
          <h3>
            <img src="/img/upgrade-plan.svg" class="img-fluid" alt="" />{{$t("profile.contents.plans&billing.plans.up-modal-title")}}
          </h3>
          <hr />
        </div>
        <div class="col-md-12">
          <div class="bottom-warning text-center">
            <p>
              {{$t("profile.contents.plans&billing.plans.up-modal-p1")}}
              <span>{{ selectPlan.type }} {{ selectPlan.name }}</span>
            </p>
            <p>{{$t("profile.contents.plans&billing.plans.up-modal-p2")}}</p>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-5">
              <h2>{{ myPlan.name }}</h2>
            </div>
            <div class="col-md-2">
              <img src="/img/change-plan-to.svg" class="img-fluid" alt="" />
            </div>
            <div class="col-md-5">
              <h2>{{ selectPlan.type }} {{ selectPlan.name }}</h2>
            </div>
          </div>
          <hr />
        </div>
        <div class="col-md-12">
          <b-button
            variant="primary"
            class="approve-button"
            @click="approveUpgrade()"
          >
            {{$t("buttons.approve")}}
          </b-button>
        </div>
      </div>
    </sweet-modal>

    <sweet-modal class="modal-x" id="changePlansDowngradeModal" ref="cancelModal">
      <div class="row">
        <div class="col-md-12 text-left">
          <h3>
            <img src="/img/cancel-plan.svg" class="img-fluid" alt="" />
            {{$t("buttons.cancelplan")}}
          </h3>
          <hr />
        </div>

        <div class="col-md-12">
          <div
            class="bottom-warning text-left"
            style="padding: 8px 10px 10px 14px"
          >
            <div class="row">
              <div class="col-md-1 pr-0">
                <img
                  src="/img/cancel-plan-warning.svg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="col-md-11">
                <p v-html="$t('profile.contents.plans&billing.plans.down-modal-p1')"> 
                 
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-12">
          <div class="row mb-3">
            <div class="col-md-5">
              <h2>{{ myPlan.type }} {{ myPlan.name }}</h2>
            </div>
            <div class="col-md-2">
              <img src="/img/cancel-to.svg" class="img-fluid" alt="" />
            </div>
            <div class="col-md-5">
              <h2 style="text-align: left; padding-left: 20px">Community</h2>
            </div>
          </div> -->

          <!-- <div class="row mb-4">
            <div class="col-md-5">
              <ul class="cancelUl">
                <li v-for="item in myPlan.info.specs" :key="item.id">
                  {{ item }}
                </li>
              </ul>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-5">
              <ul class="cancelUl">
                <li>3 Requests</li>
                <li>3 Quotations</li>
                <li>100 km Radius to Match</li>
                <li>Single Location</li>
                <li>Search by Radius Limit</li>
              </ul>
            </div>
          </div> -->

          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <p class="cancelSubP">
                 {{$t("profile.contents.plans&billing.plans.down-modal-p2")}}
                </p>
              </div>
            </div>
          </div>
          <hr style="margin-bottom: 17px" />
        </div>
        <div class="col-md-12">
          <b-button
            @click="cancelPlan"
            variant="primary"
            class="btn btn-danger cancel-btn-popup"
            >{{$t("buttons.cancel")}}</b-button
          >
        </div>
      </div>
    </sweet-modal>
  </b-container>
</template>


<script>
import MY_PLAN from "@/graphql/me/profile/getMyPlan.graphql";
import CANCEL_PLAN from "@/graphql/me/profile/cancelPlan.graphql";
import ALL_PLANS from "@/graphql/public/getAllPlans.graphql";
import SUBSCRIBE_PLAN from "@/graphql/me/sendSubsPlan.graphql";
import _ from "lodash";

export default {
  data() {
    return {
      selectedPlanType: "single",
      planTypes: [
        { value: "single", text: this.$t('buttons.single') },
        { value: "enterprise", text:  this.$t('buttons.enterprise') },
      ],
      accountChange: false,
      isNew: false,
      loading3d: true,
      paddle3dUrl: null,
      selectPlan: {},
      isSubPlan: false,
      allPlans: {},
      hover: false,
      enterprisePlans: [
        {
          name: "Starter",
          price: 0,
          discount: 0,
          billed: 0,
          specs: [
              "enterprise-com.feature1", 
              "enterprise-com.feature2", 
              "enterprise-com.feature3", 
              "enterprise-com.feature4", 
              "enterprise-com.feature5", 
              "enterprise-com.feature6"
          ],
        },
        {
          name: "Pro",
          price: 910,
          discount: 1020,
          billed: 204,
          specs: [
            "enterprise-pro.feature1", 
            "enterprise-pro.feature2", 
            "enterprise-pro.feature3", 
            "enterprise-pro.feature4", 
            "enterprise-pro.feature5", 
            "enterprise-pro.feature6"
          ],
        },
        {
          name: "Premium",
          price: 1290,
          discount: 1440,
          billed: 288,
          specs: [
              "enterprise-pre.feature1", 
              "enterprise-pre.feature2", 
              "enterprise-pre.feature3", 
              "enterprise-pre.feature4", 
              "enterprise-pre.feature5", 
              "enterprise-pre.feature6"
          ],
        },
      ],
      myPlan: {},
      loading: true,
      cancelLoading: false,
      cancelPlanDate: null,
      cancelled: false,
    };
  },
  methods: {
    calcSavePaid(item, type, index) {
        let sorted = _.orderBy(_.toPairs(this.PLAN_TYPES), ['[1].day'], ['desc']);
        let longKey = _.get(sorted, '[0][0]');
        let longPlan = _.get(this, `PLAN_TYPES.${longKey}`);
        let cond = (
            item.default == false 
            && 
            _.isEqual(_.get(this, `PLAN_TYPES.${type}`), longPlan) == false
        );

        if (cond === false) {
            return false;
        }

        let itemPaidPerDay = _.get(item, 'info.prices.EUR') / _.get(this, `PLAN_TYPES.${type}.day`);
        let longItemPaid = _.get(this,`allPlans.${longKey}[${index}].info.prices.EUR`);
        return (itemPaidPerDay * _.get(this, `PLAN_TYPES.${longKey}.day`)) - longItemPaid;
    },
    cancelPlanModal() {
      this.$refs.cancelModal.open();
    },

    openUpgradeModal(plan) {
        this.selectPlan = plan;
        this.$refs.upgradeModal.open();
    },

    approveUpgrade() {
      this.accountChange = true;
      this.$refs.upgradeModal.close();
      this.subscribePlan();
    },

    onload3d() {
      this.loading3d = false;
    },

    async subscribePlan() {
      try {
        let params = { plan_id: this.selectPlan.id };
        let data = await this.$apollo.mutate(SUBSCRIBE_PLAN, params);
        let response = data("**.subscribe");
        if(response.new) {
          this.paddle3dUrl = response.url;
          this.isSubPlan = true;
          this.isNew = true;
          return;
        }

        this.getAllPlans();
        this.getMyPlan();
      } catch (e) {
         return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "secondary",
          autoHideDelay: 1350,
        });
      }
    },
    hiddenModal() {
      this.loading3d = true;
      this.isSubPlan = false;
      this.selectPlan = { };
    },

    async getAllPlans() {
      try {
        let data = await this.$apollo.query(ALL_PLANS);
        let response = data("**.plans");
        for (let item of response) {

        }

        let plans = _.groupBy(_.orderBy(response, ['level'], ['asc']), (item) => item.type);
        for (let key in plans) {
          await this.$nextTick();
          this.$set(this.allPlans, key, _.concat(plans["*"], plans[key]));
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    refreshPage() {
      this.cancelLoading = true;
      location.reload();
    },

    async cancelPlan() {
      try {
        this.cancelLoading = true;
        let data = await this.$apollo.mutate(CANCEL_PLAN);
        this.cancelPlanDate = data("**.cancel");
        this.cancelled = true;
        this.$refs.cancelModal.close();
        this.getAllPlans();
        this.getMyPlan();
      } catch (e) {
        
        this.cancelled = false;
        return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
        this.cancelLoading = false;
      }
    },

    async getMyPlan() {
      try {
        let data = await this.$apollo.query(MY_PLAN);
        this.myPlan = _.merge(data("**.get"), data("**.get.plan"));
      } catch (e) {
        
        return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getMyPlan();
    await this.getAllPlans();
  },
};
</script>